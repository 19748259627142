@import '../../variables.scss';

.header {
    background-color: #B84719;
    height: 130px;
    
    .logo {
        width: 100%;
        display: block;
        @media (max-width: $screen-phone) {
            max-width: $phone-xs-width;
        }
        @media (min-width: $screen-phone) {
            max-width: $phone-width;
        }
        @media (min-width: $screen-tablet) {
            max-width: $tablet-width;
        }
        @media (min-width: $screen-desktop) {
            max-width: $desktop-width;
        }
        @media (min-width: $screen-lg-desktop) {
            max-width: $base-width;
        }
        margin: 0 auto;
        text-align: left;
        height: 85px;
        padding-top: 20px;

        h1 {
            float:left;
            margin: 28px 0px 0px 10px;
            @media (max-width: $screen-phone) {
                margin: 14px 0px 0px 10px;
            }
            font-size: 24px;
            color: #fff;
        }

        img {
            float:left;
            width: 90px;
            height: 90px;
            @media (max-width: $screen-phone) {
                width: 60px;
                height: 60px;
            }
        }

        .input-group {
            width: 200px;
            float:right;

            input, button {
                height: 30px;
                line-height: 0.8;
                font-size: 14px;
            }
        }
    }
}

nav {
    text-align: right;
    width: $base-width;
    @media (max-width: $screen-phone) {
        width: $phone-xs-width;
    }
    @media (min-width: $screen-phone) {
        width: $phone-width;
    }
    @media (min-width: $screen-tablet) {
        width: $tablet-width;
    }
    @media (min-width: $screen-desktop) {
        width: $desktop-width;
    }
    @media (min-width: $screen-lg-desktop) {
        width: $base-width;
    }
    margin: 0 auto;
}

.menu {
    margin: 0;
    display: inline-block;

    @media (max-width: $screen-phone) {
        padding: 0;
    }

    li {
        padding: 6px 0px 6px;
        float: left;
        margin: 0;
        list-style-type: none;
        font-size: 18px;

        a.active {
            border-bottom: 5px solid #A2AF9F;
        }
        a {
            padding: 10px 15px;
            color: #fff;
            text-decoration: none;

            @media (max-width: $screen-tablet) {
                padding: 10px 12px;
            }

            @media (max-width: $screen-phone) {
                padding: 10px 10px;
            }

            &:hover {
                border-bottom: 5px solid #fff;
            }
        }
    }
}

