@import '../../variables.scss';

.sidebar {
    max-width: 300px;
    @media (max-width: $screen-tablet) {
        max-width: 100%;
        margin-left: 0;
    }
    width: 100%;
    margin-left: 15px;
}