@import '../../variables.scss';

.image-wrapper {
    //position: relative;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
    background: #000;
    color: #fff;
    border-radius: 5px;
    box-shadow:
        inset 0 80px 40px -20px rgba(0, 0, 0, 0.55),
        inset 0 -100px 40px 0 rgba(0, 0, 0, 0.55);
    font-size: 16px;
    animation: scale-in 0.2s ease-out;
    width: 100%;
    height: 100%;
}

@keyframes scale-in {
    0% { transform: scale(0); }
    100% { transform: scale(1); }
}

.top {
    position: absolute;
    top: 25px;
    right: 15px;
    z-index: 100;
    margin: 0 12px;
    cursor: pointer;
    transition: transform 0.2s;
    user-select: none;

    &:hover {
        transform: scale(1.2);
    }
}

.link {
    color: #fff !important;
}

.image {
    width: 100%;
    height: 100%;
    user-select: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;

    img {
        max-width: 100%;
        object-fit: contain;
        display: block;
        margin: 0 auto;
        max-height: 100%;
        width: auto;
        height: auto;
    }
}

.comment {
    position: absolute;
    bottom: 20px;
    left: 0;
    padding: 0 20px;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    white-space: pre-line;
}

.shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow:
        inset 0 80px 40px -20px rgba(0, 0, 0, 0.55),
        inset 0 -80px 40px -20px rgba(0, 0, 0, 0.55);
    top: 0;
    left: 0;
    user-select: none;
}

.previous {
    position: absolute;
    z-index: 100;
    top: calc(50% - 25px);
    left: 20px;
    font-size: 40px;
    user-select: none;
    transition: transform 0.2s;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
}

.next {
    position: absolute;
    z-index: 100;
    top: calc(50% - 25px);
    right: 20px;
    font-size: 40px;
    user-select: none;
    transition: transform 0.2s;
    cursor: pointer;

    &:hover {
        transform: scale(1.2);
    }
}

.loading-icon {
    width: 100px !important;
    height: 100px !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 2px;
}


#controls {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    width: 100%;
    padding-bottom: 3px;
}
.ctrl {
    padding: 8px 5px;
    width: 30px;
    text-align: center;
    background: rgba(83, 83, 83, 0.8);
    display: inline-block;
    cursor: pointer;
    font-size: 18px;
}
.ctrl:hover {
    background: rgba(200, 200, 200, 1);
}


.panorama-icon {
    left: 10px;
    top: 15px;
    z-index: 99;
    cursor: default;
    &:hover {
        transform: scale(1);
    }
    img {
        width: 50px;
    }
}