@import '../../variables.scss';
footer {
    width: $base-width;
    @media (max-width: $screen-phone) {
        width: $phone-xs-width;
    }
    @media (min-width: $screen-phone) {
        width: $phone-width;
    }
    @media (min-width: $screen-tablet) {
        width: $tablet-width;
    }
    @media (min-width: $screen-desktop) {
        width: $desktop-width;
    }
    @media (min-width: $screen-lg-desktop) {
        width: $base-width;
    }
    margin: 0 auto;
}
