@import '../../variables.scss';

.content {
    width: 100%;
    max-width: $base-width;
    display: flex;
    flex-grow: 1;
    @media (max-width: $screen-phone) {
        max-width: $phone-xs-width;
        flex-direction: column;
    }
    @media (min-width: $screen-phone) {
        max-width: $phone-width;
        flex-direction: column;
    }
    @media (min-width: $screen-tablet) {
        max-width: $tablet-width;
        flex-direction: row;
    }
    @media (min-width: $screen-desktop) {
        max-width: $desktop-width;
        flex-direction: row;
    }
    @media (min-width: $screen-lg-desktop) {
        max-width: $base-width;
        flex-direction: row;
    }
    margin: 25px auto 0;
    display: flex;
    flex-direction: row;

    h1 {
        margin-top: 0;
    }
}